export const Feature = ({ imageSrc, title, description, reverse }) => {
  return (
    <div className={`row ${reverse ? "row-reverse" : ""}`}>
      {reverse ? (
        <>
          <div className="col-lg-6 col-lg-offset-1 col-md-6 col-md-offset-1 col-sm-12 col-xs-12 feature-text">
            <div className="feature-text-container">
              <div
                dangerouslySetInnerHTML={{ __html: title }}
                className="large-text"
              />{" "}
              <br />
              <div>{description}</div>
            </div>
          </div>
          <div className="illustration col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <img src={imageSrc} alt="logo" />
          </div>
        </>
      ) : (
        <>
          <div className="illustration col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <img src={imageSrc} alt="logo" />
          </div>
          <div className="col-lg-8 col-sm-12 col-md-8 col-xs-12 feature-text">
            <div className="feature-text-container">
              <div
                dangerouslySetInnerHTML={{ __html: title }}
                className="large-text"
              />{" "}
              <br />
              <div>{description}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
