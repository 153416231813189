import React from "react";
import { Feature } from "./feature";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="artist-blurb-container">
        {/* <div className="artist-blurb">For artists, by artists.</div> */}
      </div>
      <div id="separator">
        {/* <div className="dark-text">
          Made <span className="underline">for</span> artists,{" "}
          <span className="underline">by&nbsp;</span>
        </div>
        <span className="light-text">artists.</span> */}
      </div>

      <div className="svg-container">
        <img src={"img/3d-cube-4.svg"} alt="logo" />
      </div>
      <div className="features-header">
        Let creation flow as fast as your ideas.
      </div>
      <div className="row video-container container">
        <div className="video-header">V1 Demo</div>

        <div className="video">
          <video width="100%" height="auto" controls>
            <source src="img/demo2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className="container">
        <div className="row features-container">
          <Feature
            imageSrc={"img/foils2/test-shape.png"}
            title="<span>Instant Creativity +&nbsp;</span><span class='feature-text'>Effortless Design</span>"
            description="Master the platform in minutes with a simple, user-friendly interface. Create stunning scenes, landscapes, and characters in just a few clicks.

"
          />
          <Feature
            imageSrc={"img/foils2/blob2.png"}
            title="<span>Endless Possibility +&nbsp;</span><span class='feature-text'>Total Control</span>"
            description="Built-in AI tools help with the heavy lifting, but you remain in complete creative control. Instantly edit, adjust, and enhance every vertex, polygon, and texture."
            reverse
          />
          <Feature
            imageSrc={"img/foils/foil5.png"}
            title="<span>Flexible +&nbsp;</span><span class='feature-text'>Seamless Integration</span>"
            description="In Moonflower, you can seamlessly switch between manual editing and AI tools to refine your designs. Once complete, export your creations in a variety of industry-standard formats."
          />
        </div>
      </div>
    </div>
  );
};
