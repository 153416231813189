import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <svg>
        <filter id="noiseFilter">
          <feTurbulence
            type="fractalNoise"
            baseFrequency="0.6"
            stitchTiles="stitch"
          />
          <feColorMatrix
            in="colorNoise"
            type="matrix"
            values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"
          />
          <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
          <feBlend in="SourceGraphic" in2="monoNoise" mode="screen" />
        </filter>
      </svg>
      <div className="intro">
        <div className="overlay-texture"></div>
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 intro-text">
              <h1>
                {props.data ? props.data.title : "Loading"}
                <span></span>
              </h1>
              <p>{props.data ? props.data.paragraph : "Loading"}</p>
              <a href="#about" className="btn btn-custom btn-lg page-scroll">
                Join Waitlist
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="artist-blurb-container">
        <div className="artist-blurb">For artists, by artists.</div>
      </div> */}
    </header>
  );
};
