import React, { useState } from "react";

export const About = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    updates: false,
  });
  const [submissionStatus, setSubmissionStatus] = useState(null); // New state for submission status

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://server.labkick.ai/api/moonflower-signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        setSubmissionStatus("success"); // Set status to success
        console.log("Form submitted successfully");
      } else {
        setSubmissionStatus("error"); // Set status to error
        console.error("Form submission failed");
      }
    } catch (error) {
      setSubmissionStatus("error"); // Set status to error
      console.error("Error:", error);
    }
  };

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div id="cutout-container">
            <img
              id="cutout"
              src={"img/cutout_small.png"}
              alt="waitlist-blurb"
            />
          </div>
          <div className="waitlist-blurb col-xs-12 col-md-10 col-md-offset-1">
            Moonflower is almost here.
            <br></br>
            <br></br>
          </div>
          <div className="col-xs-12 col-md-8 col-md-offset-2">
            {submissionStatus === "success" && (
              <div className="alert alert-success" role="alert">
                Form submitted successfully!
              </div>
            )}
            {submissionStatus === "error" && (
              <div className="alert alert-danger" role="alert">
                Form submission failed. Please try again.
              </div>
            )}
            <form id="waitlist-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group checkbox-container checkbox-wrapper-13">
                <input
                  type="checkbox"
                  id="cl-13"
                  name="updates"
                  checked={formData.updates}
                  onChange={handleChange}
                />
                <label htmlFor="cl-13">Send me updates</label>
              </div>
              <button type="submit" className="btn btn-custom btn-lg">
                Join Waitlist
              </button>
            </form>
          </div>
          <div className="waitlist-blurb small-text col-xs-12 col-md-10 col-md-offset-1">
            If you'd like to learn more, please email us:&nbsp;
            <a href="mailto:hello@moonflower.dev">hello@moonflower.dev</a>
          </div>
        </div>
      </div>
    </div>
  );
};
